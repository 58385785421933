import {useCallback, useEffect, useRef, useState} from "react";
import {ISapphirePortalComm} from "../comm/WebSocket";
import { GuestsManager } from "../store/DataStructures";


export interface RTCPeerInfo{
    description:RTCLocalSessionDescriptionInit, 
    candidates:RTCIceCandidate[],
    rtcConnectionID:string
};

export interface RTCConnectionInfo{
    rtcConnection:RTCPeerConnection,
    description:RTCLocalSessionDescriptionInit, 
    candidates:RTCIceCandidate[],
    stream:MediaStream,
    guestName: string
};

export interface CastingConnectionInfo{
    connections:Map<string, RTCConnectionInfo>,
    mainConnectionID:string
};

const DEFAULT_ICE_SERVER = {
    urls : "turn:turn.userful.com",
    username : "userful",
    credential : "sh37wu"
};

export default function useWebRTCCaptureMrg(
		pairingCode:string, 
		sapphirePortalComm:ISapphirePortalComm,
		guestsManager:GuestsManager) {

    const streamRef = useRef<MediaStream>();
	const rtcConnectionRef = useRef<RTCPeerConnection>();
    const isWaitingForAnswerRef = useRef<boolean>(false);

	const [isCasting, setCasting] = useState<boolean>(false);

	const startCasting = useCallback((callback?: () => void) => {
		function createConnection() {
			let rtcConnection = rtcConnectionRef.current;
			if (rtcConnection) {
				rtcConnection.close();
			}

			let config:RTCConfiguration = {iceServers: [DEFAULT_ICE_SERVER]};

			const pathname = window.location.pathname;
			console.log("pathname: " + pathname);
			if (pathname.startsWith("/meet")) {
				// This is air-gapped.		
				console.log("This is air-gapped.");		
				config = {iceServers: []};
			} 
			console.log("use ice config:", config);
			let localDescription:RTCLocalSessionDescriptionInit = null;
	
			rtcConnection = rtcConnectionRef.current = new RTCPeerConnection(config);
	
			const icecandidates:RTCIceCandidate[] = [];
			let isSentOffer = false;

			rtcConnection.onicecandidate = event => {
				console.debug("onicecandidate");
				if (event.candidate && event.candidate.candidate) {
					icecandidates.push(event.candidate);
				} else if (!isSentOffer) {
					// All candidates gethered.
					console.debug("candidatesJSON:" + JSON.stringify(icecandidates));
					sendOffer(localDescription, icecandidates);	
					isSentOffer = true;		
				}
			}; 
			
			setTimeout(() => {
				// All candidates gethered.
				console.debug("candidatesJSON:" + JSON.stringify(icecandidates));
				if (!isSentOffer) {
					sendOffer(localDescription, icecandidates);	
					isSentOffer = true;	
				}
			}, 3000);
			
			async function setVideoParams(sender) {
				try{
					const params = sender.getParameters();
					params.degradationPreference = 'maintain-resolution';
					for(let i = 0; i < params.encodings.length; i++) {
						params.encodings[i].adaptivePtime = true;
						params.encodings[i].networkPriority = "high";
						params.encodings[i].priority = "high";
					}
					await sender.setParameters(params);
				}
				catch(error){
					console.log(`An error occurred: ${error.message}`);
				}
			}

			async function setVideoCodecParams(sender) {
				rtcConnection.getTransceivers().forEach(transceiver => {
					if (transceiver.receiver) {
						// Define your preferred codecs here. Make sure they are available.
						const codecs = RTCRtpReceiver.getCapabilities('video').codecs;
						codecs.sort((a, b) => {
							if (a.mimeType === 'video/AV1') return -1;
							if (b.mimeType === 'video/AV1') return 1;
			
			
							if (a.mimeType === 'video/VP8') return -1;
							if (b.mimeType === 'video/VP8') return 1;
			
							return 0; // If codecs are neither AV1, VP9, nor VP8, retain their order
						});
			
						// Filter out the codecs we're interested in (you can expand this list if needed)
						const preferredCodecs = codecs.filter(codec => 
							['video/AV1', 'video/VP9', 'video/VP8'].includes(codec.mimeType)
						);
						// Set the codec preferences for this transceiver
						try {
							if (transceiver.receiver.track.kind === 'video')
							transceiver.setCodecPreferences(codecs);
						} catch (err) {
							console.error('Failed to set codec preferences:', err);
						}
					}
				});
			}

			streamRef.current.getTracks().forEach(track => {
				console.log("Adding track: " + track.label);
				const sender = rtcConnection.addTrack(track, streamRef.current);
				setVideoParams(sender);
				setVideoCodecParams(sender);     
			});
	
			rtcConnection.onconnectionstatechange =  e => {
				let state = rtcConnection.connectionState;				
				console.log("onconnectionstatechange. connectionState=" + state);
				if (state === "connected") {
					setCasting(true);
				// Somehow this event won't be triggered if we close the connection ourselves. 
				// So use a timer to check the connection status instead.
	//			} else if (state === "disconnected" 
	//				|| state === "failed"
	//				|| state === "closed") {
	//				theInstance.@com.userful.ucc.client.pres.WebRTCCaptureMrg::endCapture()();
	//				theInstance.@com.userful.ucc.client.pres.WebRTCCaptureMrg::showInputPanel()();	
				}
			};
			  
			streamRef.current.addEventListener('inactive', event => {
				console.log("Capture stream inactive. Closing the RTPconnection.");
				rtcConnection.close();
			});
	
			let checkingInterval = setInterval(() => {
				console.log("connectionState=" + rtcConnection.connectionState);
				if (rtcConnection.connectionState === "disconnected" 
						|| rtcConnection.connectionState === "failed" 
						|| rtcConnection.connectionState === "closed") {
					clearInterval(checkingInterval);
					endCapture();
					setCasting(false);			
				}
			}, 1000);	
			
			const options = {
				offerToReceiveAudio: true, 
				offerToReceiveVideo: true
			};

			rtcConnection.createOffer(options).then(
				desc => {
					console.debug("desc:" + desc);
					rtcConnection.setLocalDescription(desc);
					localDescription = desc; 
				},
				error => {
					console.error('Failed to create session description: ', error);
				}
			);
		}
		
		function sendOffer(description:RTCLocalSessionDescriptionInit, candidates:RTCIceCandidate[]) {
		
			console.debug("Sending Offer:");
			console.debug("Description:", description);
			console.debug("Candidate:" + JSON.stringify(candidates));
			
			const offer:RTCPeerInfo = {
				description: description,
				candidates: candidates,
				rtcConnectionID: guestsManager.thisPortalGuestName
			}
	
			sapphirePortalComm.startCasting(offer);
	
			console.log("isWaitingForAnswer=" + isWaitingForAnswerRef.current);
			isWaitingForAnswerRef.current = true;
		}

		if (pairingCode) {
			navigator.mediaDevices.getDisplayMedia({audio:true, video: true}).then(
				stream => {
					console.log("get capture stream");
					//videoElement.srcObject = stream;
					streamRef.current = stream;
					createConnection();
					typeof callback === 'function' && callback();
				}, 		    
				error => {
					console.error('Failed to capture the screen: ' + error.toString());
				}
			);
		}
		
	}, [pairingCode, sapphirePortalComm.startCasting, endCapture])

	function endCapture() {
		if (streamRef.current) {
			let tracks = streamRef.current.getTracks();
			tracks.forEach(track => track.stop());
		}
		rtcConnectionRef.current.close();
	}

	const endCasting = useCallback(() => {
		endCapture();
	}, [endCapture]);

	const muteSound = useCallback(() => {
		if (streamRef.current) {
			let tracks = streamRef.current.getAudioTracks();
			if (streamRef.current.getAudioTracks()[0].enabled === true){
				tracks.forEach(track => track.enabled = false);
			}
			else{
				tracks.forEach(track => track.enabled = true);
			}
		}
	}, []);

	const isMuted = () => {
		let tracks = streamRef.current.getAudioTracks()[0];
		if (tracks) {
			if (tracks.enabled === true){
				return true
			}
			else{
				return false
			}
		}
		else{
			return undefined;
		}
	};

	useEffect(() => {
		console.log("gotWebrtcAnswer: isWaitingForAnswer=" + isWaitingForAnswerRef.current);
		if (sapphirePortalComm.webrtcAnswer) {
			if (!isWaitingForAnswerRef.current) {
				return;
			}
			const rtcConnection = rtcConnectionRef.current;
			if (rtcConnection && rtcConnection.signalingState === "stable") {
				console.warn("connection is stable. Not doing anything");
				return;
			}		
			
			console.log("setRemoteDescription");
			rtcConnection.setRemoteDescription(new RTCSessionDescription({type: sapphirePortalComm.webrtcAnswer.description.type, sdp: sapphirePortalComm.webrtcAnswer.description.sdp})).then(() => {
				sapphirePortalComm.webrtcAnswer.candidates.forEach(candidate => {
					console.log("addIceCandidate");
					rtcConnection.addIceCandidate(new RTCIceCandidate(candidate));
				});
			});

			isWaitingForAnswerRef.current = false;
		}
    }, [sapphirePortalComm.webrtcAnswer])


	return {startCasting, endCasting, isCasting, muteSound, isMuted};
}

