import { Dispatch, SetStateAction, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import "./css/sourcesform.scss";
import "./css/PairingCode.scss";
import "./css/InputPanel.scss";
import MultiConnectionWarningModal, { WrongMeetingRoomModal } from "../common/Modal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import useSapphirePortalComm, { ISapphirePortalComm } from '../comm/WebSocket';
import {Layout, LayoutItem, GuestNames} from "../comm/DataStructures";

interface IProps {
    pairingCode: string;
    setPairingCode: Dispatch<SetStateAction<string>>;
    setMeeting: Dispatch<SetStateAction<boolean>>;
    setVideoConferencePage: Function;
    sapphirePortalComm:ISapphirePortalComm;

}

export default function JoinMeetingSpace(props: IProps) {

    const { t } = useTranslation();
    const [showMultipleConnectionsWarning, setShowMultipleConnecctionsWarning] = useState(false);
    const [showWrongMeetingRoomWarning, setShowWrongMeetingRoomWarning] = useState(false);
    const [selectConferenceTool, setConferenceTool] = useState("uCast");

    // Try to retrieve meeting room id from the URL query.
    const urlParams = new URLSearchParams(window.location.search);
    const uriID = urlParams.get("id");
    // console.log("uriID:", uriID);
    let defaultMeetingRoomID = "";
    if (uriID) {
        defaultMeetingRoomID = decodeURI(uriID);
    } else {
        // Try to retrieve meeting room id from the URL path.
        let pathname = window.location.pathname;
        if (pathname.startsWith("/meet")) {
            pathname = pathname.substring("/meet".length);
        }
        if (pathname.startsWith("/")) {
            pathname = pathname.substring(1);
        }
        defaultMeetingRoomID = decodeURI(pathname);
    }

    const navigate = useNavigate();


    function handleJoinButtonClick() {
        if (!props.pairingCode) {
            console.log("handleJoinButtonClick:H0");
            setShowWrongMeetingRoomWarning(true);
            return;
        }

        // checks if its correct pairing code or not
        
        props.sapphirePortalComm.registerPortal(props.pairingCode, "").then((available)=>{
            if (available) {
                //joined the meeting successfully
                props.setMeeting(true);
                props.setVideoConferencePage(true);
                navigate("/meeting");
            } else {
                //failed to join meeting
                console.log("handleJoinButtonClick:H2");
                setShowWrongMeetingRoomWarning(true);
                return Promise.reject(new Error("Wrong meeting room pairing code."));
            }
        }).catch((error) => {
            //catch error if occurs
            console.error("Error during meeting room setup: ", error);
        });
    }

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleInput = (e, index) => {
        const input = e.target;
        let newValue = input.value.toUpperCase();

        if (!/^[0-9a-zA-Z]*$/.test(newValue)) {
            // If input is not a number or letter, replace with an empty string
            input.value = "";
            newValue = "";
            return;
        }

        if (newValue.length > 1) {
            // In case more than one character is entered, only use the first character
            input.value = newValue.charAt(0);
            newValue = input.value;
        }

        if (index < inputRefs.length - 1 && newValue.length === 1) {
            inputRefs[index + 1].current.focus();
        } else if (index > 0 && newValue === "") {
            inputRefs[index - 1].current.focus();
        }

        input.value = newValue; // Update the input with the uppercase value

        const newCombinedValue = inputRefs.map((ref) => ref.current.value).join("");
        props.setPairingCode(newCombinedValue);
    };

    const handleArrowKey = (e, index) => {
        if (e.key === "Backspace" && index > 0) {
            e.preventDefault();

            inputRefs[index].current.value = "";
            inputRefs[index - 1].current.focus();

            const newCombinedValue = inputRefs.map((ref) => ref.current.value).join("");

            props.setPairingCode(newCombinedValue);
        } else if (e.key === "ArrowLeft" && index > 0) {
            inputRefs[index - 1].current.focus();
        } else if (e.key === "ArrowRight" && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    return (
        <div className="main-container">
            <div className="sourceFormWrapper">
                <div className="sourceFormHeader">
                    <div className="sourceFormHeaderTitle">{t('PortalApp.EnterPairingCode')}</div>
                    <div className="sourceFormHeaderDescription">
                        {t('PortalApp.EnterCodeDes')}
                    </div>
                </div>

                <div className="sourceFormBlock">
                    <div className="pairingCodeWrapper">
                        {inputRefs.map((ref, index) => (
                            <input
                                key={index}
                                type="text"
                                className="pairingCodeField"
                                ref={ref}
                                onInput={(e) => handleInput(e, index)}
                                onKeyDown={(e) => {
                                    handleArrowKey(e, index);
                                }}
                            />
                        ))}
                    </div>
                </div>
                <div className="sourceFormBody">
                    <div className="sourceFormBlock">
                        <div
                            className={`sourceFromBlockButton ${props.pairingCode.length !== 4
                                ? "disabledButton"
                                : null
                                }`}
                            onClick={handleJoinButtonClick}

                        >
                            {t('PortalApp.Next')}
                            <FontAwesomeIcon icon={faArrowRight} className="arrowIconRigth" />
                        </div>
                    </div>
                </div>

                <MultiConnectionWarningModal
                    show={showMultipleConnectionsWarning}
                    handleClose={() => setShowMultipleConnecctionsWarning(false)}
                    handleShareScreen={() => {
                        props.setMeeting(true)
                        props.setVideoConferencePage(true)
                        navigate("/meeting")

                    }}

                />

                <WrongMeetingRoomModal
                    show={showWrongMeetingRoomWarning}
                    handleClose={() => setShowWrongMeetingRoomWarning(false)}
                />

            </div>
        </div>
    );
}
