import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    variant: string,
    width?: string,
    children: React.ReactNode,
    disabled?: boolean
}

const getStyle = (hover, variant, disabled?) => {
    if (variant === 'primary' && disabled) {
        return {
            borderRadius: '8px',
            fontWeight: '600',
            outline: 'none',
            border: 'none',
            height: '36px',
            padding: '0px 16px',
            color: 'var(--userful-white)',
            background: 'var(--userful-primary-blue)',
            opacity: 0.5,
            cursor: 'not-allowed',
        };
    }

    if (variant === 'secondary' && disabled) {
        return {
            borderRadius: '8px',
            fontWeight: '600',
            outline: 'none',
            height: '36px',
            padding: '0px 16px',
            color: 'var(--userful-primary-dark)',
            background: 'var(--userful-secondary)',
            border: '1px solid #C6C8CE',
            opacity: 0.5,
            cursor: 'not-allowed',
        };
    }

    if (variant === "danger" && disabled) {
        return {
            background: "#D13E2C",
            borderRadius: '8px',
            fontWeight: '600',
            color: '#FFFFFF',
            outline: 'none',
            border: 'none',
            height: '36px',
            padding: '0px 16px',
            opacity: 0.7,
            cursor:'not-allowed'
        }
    }

    const style = {
        secondary: {
            background: hover ? "#EAEBEE" : "#F3F4F5",
            borderRadius: '8px',
            fontWeight: '600',
            color: '#1F2747',
            outline: 'none',
            border: '1px solid #C6C8CE',
            height: '36px',
            padding: '0px 16px',
        },
        primary: {
            background: hover ? "#1C3FB0" : "#234FDC",
            borderRadius: '8px',
            fontWeight: '600',
            color: '#FFFFFF',
            outline: 'none',
            border: 'none',
            height: '36px',
            padding: '0px 16px',
        },
        danger: {
            background: hover ? "#A73223" : "#D13E2C",
            borderRadius: '8px',
            fontWeight: '600',
            color: '#FFFFFF',
            outline: 'none',
            border: 'none',
            height: '36px',
            padding: '0px 16px',
        },
        success: {
            background: hover ? "#1A7E4D" : "#23A866",
            borderColor: '#23A866',
            borderRadius: '8px',
            fontWeight: '600',
            color: '#FFFFFF',
            outline: 'none',
            border: 'none',
            height: '36px',
            padding: '0px 16px',

        },
        creation: {
            background: hover ? "#1A7E4D" : "#23A866",
            borderColor: '#23A866',
            borderRadius: '8px',
            fontWeight: '600',
            color: '#FFFFFF',
            outline: 'none',
            border: 'none',
            height: '36px',
            padding: '0px 16px',

        },
    };
    return style[variant];
};

const Button = (props: IProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
    const [hover, setHover] = useState(false);

    return (
        <button
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={getStyle(hover, props.variant, props.disabled)}
            {...props}
        > {props.children}
        </ button >
    );
}

export default Button;