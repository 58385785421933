import { useCallback, useRef } from "react";

const CANDIDATE_COLORS:string[] = ["#34568B", "#FF6F61", "#6B5B95", "#88B04B", "#955251", "#B565A7", "#009B77", "#DD4124", "#D65076"]

export const GetRandomColor = () => {
    const guestNameColorMappingRef = useRef(new Map());
    const candidateColorIndexRef = useRef(0);

    const getColor:(key:string)=>string = useCallback((key:string) => {
        let color = guestNameColorMappingRef.current.get(key);
        if (!color) {
            color = CANDIDATE_COLORS[candidateColorIndexRef.current];
            guestNameColorMappingRef.current.set(key, color);
            candidateColorIndexRef.current++;
            if (candidateColorIndexRef.current === CANDIDATE_COLORS.length) {
                candidateColorIndexRef.current = 0;
            }
        }
        console.log("Got " + color + " for " + key);
        return color;
    }, []);

    return getColor;
}