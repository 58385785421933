import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useWebSocket, { Options as WebSocketOptions} from "react-use-websocket";

import {RTCPeerInfo} from "../webrtc/Messaging";
import {SECURITY_POLICY, PORTAL_VERSION, API_VERSION}from "./BackendVersioning"
import {GuestsManager,  LayoutManager, LayoutItem } from '../store/DataStructures';

import {v4 as uuidv4} from 'uuid';


const SAPPHIRE_API = "sapphire-api";
const POSSIBLE_PREFIXES = ["meet.", "sapphire."]

export interface ISapphirePortalComm {
    registerPortal: (pairingCode: string, authToken: string) => Promise<boolean>;
    layoutUpdate: (layout: LayoutItem[]) => void;
    joinMeeting: (guestName: string, isCasting: boolean) => void;
    leaveMeeting: (reason: number, message: string) => void;
    startCasting: (offer: RTCPeerInfo) => void;
    stopCasting: () => void;
    webrtcAnswer: RTCPeerInfo;
}
  

export default function useSapphirePortalComm(setMeetingRoomID:Function, guestsManager: GuestsManager, layoutManager: LayoutManager) {
    let webSocketOptions:WebSocketOptions = {
        share: true,
        shouldReconnect: (closeEvent) => {
            console.log("websocket closed. closeEvent=" + JSON.stringify(closeEvent));
            return true;
        },
        reconnectAttempts: 1000,
        reconnectInterval: 3000,
    };

    // const currentGuests = useSelector((store: any) => store.currentGuests);

    // const dispatch = useDispatch();

    const sapphireWebsocketURL = useMemo(() => {
        const currentPageHost = window.location.hostname;
        const currentPagePort = window.location.port;
        if (currentPageHost === "localhost") {
            // This is in a dev enviornment. 
            // return "wss://sapphire-api.userful.net";
            return "wss://testnet22.userful.ca:44399/sapphire-service"
        }
        const pathname = window.location.pathname;
        console.log("pathname: " + pathname);
        if (pathname.startsWith("/meet")) {
            // This is air-gapped.
            return "wss://" + currentPageHost + (currentPagePort ? ":" + currentPagePort : "") + "/sapphire-service";
        } else {
            // This is cloud solution.
            let suffix = currentPageHost;
            for (const prefix of POSSIBLE_PREFIXES) {
                const i = currentPageHost.indexOf(prefix);
                if (i >= 0) {
                    suffix = currentPageHost.substring(i + prefix.length);
                    break;
                }
            }

            console.log("suffix:" + suffix);        
            // return "wss://" + SAPPHIRE_API + "." + suffix;
            return "wss://sapphire-api.userful.net";
        }
    }, []);

    console.log("sapphireWebsocketURL:" + sapphireWebsocketURL);     

    const {sendJsonMessage, lastJsonMessage} = useWebSocket(sapphireWebsocketURL, webSocketOptions);

    const [webrtcAnswer, setWebrtcAnswer] = useState<RTCPeerInfo>(null);

    const apiCallResponsePromiseRef = useRef<{}>({});
    
    //PortalRequestRegisterPortal
    const registerPortal = useCallback((
            pairingCode: string,
            authToken: string
        ) => {
        console.log("Register sapphire client...");
        let command = {action: "registerPortal", 
                    "pairingCode": pairingCode, 
                    "authToken": authToken, 
                    "portalVersion": PORTAL_VERSION, 
                    "apiVersion": API_VERSION, 
                    "msgID": uuidv4()};
        sendJsonMessage(command);
        return new Promise<boolean>((resolve, reject) => {
            apiCallResponsePromiseRef.current[command.msgID] = {resolve, reject};
        })
    }, [sendJsonMessage]);

    //PortalRequestUpdateLayout
    const layoutUpdate = useCallback((
        layout: LayoutItem[]
        ) => {
        let command = {action: "updateLayout", 
                     "layout": layout};
        sendJsonMessage(command);
        console.warn(command);
    }, [sendJsonMessage]);

    //PortalRequestJoinMeeting
    const joinMeeting = useCallback((
            guestName:string,
            isCasting: boolean
        ) => {
        let command = {action: "joinMeeting", "guestName": guestName, "isCasting": isCasting, "msgID":uuidv4()};
        sendJsonMessage(command);       
    }, [sendJsonMessage]);

    //PortalRequestLeaveMeeting
    const leaveMeeting = useCallback((
            reason: number,
            message: string
        ) => {
        let command = {action: "leaveMeeting", "reason" : reason, "message": message};
        sendJsonMessage(command);
        console.warn(command);
    }, [sendJsonMessage]);

    //PortalRequestStartCasting
    const startCasting = useCallback((
            offer:RTCPeerInfo, 
        ) => {
        let command = {action: "startCasting", "offer": offer, "msgID": uuidv4()};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    //PortalRequestStopCasting
    const stopCasting = useCallback(() => {
        let command = {action: "stopCasting"};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    useEffect(() => {
        if (lastJsonMessage) {
            const result = lastJsonMessage;
            console.warn(result);
            if (typeof result === "object") {
                if (result.action) {
                    if (result.action === "closeConnection") {
                        console.log("closeConnection reason: ", result.reason);
                        console.log("closeConnection message: ", result.message);
                    } else if (result.action === "layoutUpdate") {
                        layoutManager.inputLayoutAsArray(result.layout);
                    } else if (result.action === "meetingUpdate") {
                        guestsManager.addGuest(result.guestNames);
                    } else if (result.action === "castingStarted") {
                        if (typeof result.answer === "object") {
                            setWebrtcAnswer(result.answer);
                        }
                    } else if (result.action === "layoutUpdated") {
                        console.log("layoutUpdated: ", result.msgID);
                    } else if (result.action === "meetingJoined") {
                        layoutManager.inputLayoutAsArray(result.layout);
                    } else if (result.action === "clientRegistered") {
                        if (result.meetingName){
                            setMeetingRoomID(result.meetingName);
                            // setMeetingRoomDescription(result.meetingDescription);
                            console.log(apiCallResponsePromiseRef.current)
                            apiCallResponsePromiseRef.current[result.msgID].resolve(true);
                            
                            delete apiCallResponsePromiseRef.current[result.msgID]
                        } else {
                            apiCallResponsePromiseRef.current[result.msgID].resolve(false);
                            delete apiCallResponsePromiseRef.current[result.msgID]
                        }
                    }
                }
            }
        }
    }, [lastJsonMessage]);


    return {registerPortal, layoutUpdate, joinMeeting, leaveMeeting, startCasting, stopCasting, webrtcAnswer};

}
