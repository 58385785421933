import { useEffect, useState, useRef } from 'react';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';

import JoinMeetingSpace from './components/JoinMeetingSpace';
import useSapphirePortalComm, { ISapphirePortalComm } from './comm/WebSocket';
import PortalPanel from './components/PortalPanel';
import useWebRTCCaptureMrg from './webrtc/Messaging';
import UserfulPageLayout from './layout/UserfulPageLayout';
import {ConnectionMapping } from './components/ScreenSharePanel';
import { library } from '@fortawesome/fontawesome-svg-core';
import {Layout, LayoutItem, GuestNames} from "./comm/DataStructures";

import { useGuestsManager } from './store/GuestsManager';
import { useLayoutItems } from './store/LayoutManager';

import "./App.scss"
import 'bootstrap/dist/css/bootstrap.css';
import './main.scss';
import './UserfulColors.css';

import {
  faDisplay, faArrowRight, faWrench, faCircleNotch,
  faUsersCog, faCube, faSlidersH, faCloud, faDatabase, faEllipsisV, faServer, faHdd, faAngleDown, faAngleRight,
  faUndoAlt, faUserCircle, faSort, faLink, faEdit, faTrash, faCog, faMapMarkerAlt, faUsers, faChalkboardUser, faDoorOpen,
} from '@fortawesome/free-solid-svg-icons';
import { url } from 'inspector';

library.add(faDisplay, faArrowRight, faWrench, faUsersCog, faCube, faSlidersH, faCloud, faDatabase, faEllipsisV, faServer, faHdd, faDoorOpen,
  faAngleDown, faAngleRight, faUndoAlt, faUserCircle, faSort, faLink, faEdit, faTrash, faCircleNotch, faMapMarkerAlt, faCog, faUsers, faChalkboardUser);



interface IProps {
}

export default function App(props: IProps) {
  const [pairingCode, setPairingCode] = useState("");
  const [meetingRoomID, setMeetingRoomID] = useState("");
  const [guestName, setGuestName] = useState("");
  const [admitAll, setAdmitAll] = useState<boolean>(false);

  // this state is to show the video conferencing page
  const [videoConferencePage, setVideoConferencePage] = useState(false);

  // this is for casting your screen
  const [uCast, setUCast] = useState(false);
  // this is if the conference type is selected or not
  const [conferenceType, setConferenceType] = useState(-1);
  const [inMeeting, setMeeting] = useState<boolean>(false);
  // index 0 = conferance
  // index 1 = minimize

  // STORE OBJECT RERANDER ##############################
  const guestsManager = useGuestsManager();
  const layoutManager = useLayoutItems();

  const sapphirePortalComm: ISapphirePortalComm = useSapphirePortalComm(setMeetingRoomID, guestsManager, layoutManager);

  useEffect(() => {

    var urlParams = new URLSearchParams(window.location.search);
    if (window.location.pathname === "/auth") {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('code');
      if (token && window.opener) {
        window.opener.postMessage({ authCode: token });
        window.close()
      }
    }
    if(window.opener){
      let referrer = document.referrer;
      let url = new URL(referrer);
      let domain = url.hostname; // This gets the domain part of the URL
      if (window.opener && domain === "idbroker-ca.webex.com" && urlParams.size ===2 ){
        const getGlobalHost = urlParams.get("state");
        const paramString = urlParams.toString();
        if (getGlobalHost){
          window.location.replace(getGlobalHost + "?" + paramString);
        }
        
      }
    }
  });

    const { startCasting, endCasting, isCasting, muteSound } = useWebRTCCaptureMrg(pairingCode, sapphirePortalComm, guestsManager);


  return (
    <div className="App">
      
      <UserfulPageLayout className="main-container" showHelp showWindowMax >
        <Router>
          <Routes>
            <Route path="/" element={<JoinMeetingSpace
              pairingCode={pairingCode}
              setPairingCode={setPairingCode}
              sapphirePortalComm={sapphirePortalComm}
              setMeeting={setMeeting}
              setVideoConferencePage={(bool: boolean) => { setVideoConferencePage(bool) }} />
            } />

            <Route path="/meeting" element={
              <PortalPanel
                inMeeting={inMeeting}
                setMeeting={setMeeting}
                startCasting={startCasting}
                sapphirePortalComm={sapphirePortalComm}
                pairingCode={pairingCode}
                meetingRoomID={meetingRoomID}
                endCasting={endCasting}
                isCasting={isCasting}
                muteSound={muteSound}
                videoConferencePage={videoConferencePage}
                setVideoConference={(bool: boolean) => { setVideoConferencePage(bool) }}
                uCast={uCast}
                setUCast={(bool) => setUCast(bool)}
                conferenceType={conferenceType}
                setConferenceType={setConferenceType}
                setAdmitAll={(value)=> setAdmitAll(value)}
                admitAll={admitAll}
                layoutManager ={layoutManager}
                guestManager = {guestsManager}/>
              
            } />
          </Routes>
        </Router>
      </UserfulPageLayout>
    </div>
  );

}
