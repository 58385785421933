import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import 'font-awesome/css/font-awesome.min.css';

import ScreenSharePanel from './ScreenSharePanel';
import {ConnectionMapping } from './ScreenSharePanel';
// import SelectLayout from './SelectLayout';
// import AddConference from './AddConference';
import { ISapphirePortalComm } from '../comm/WebSocket';
// import ConferenceActions from './ConferenceActions';
// import { PostRoomOS } from "../roomos/Command";
// import SessionKeeper from '../resourcesharing/SessionKeeper'
// import ResourcesBar from '../resourcesharing/ResourcesBar';
import './PortalPanel.scss';
import './css/sourcesform.scss';
import './css/InputPanel.scss';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faShare, faVolume, faVolumeSlash } from '@fortawesome/pro-regular-svg-icons';
import { stopPoller } from "../roomos/Poller";
import VideoConferencingPage from "./VideoConferencingPage";
// import SelectConferenceType from "./SelectConferenceType";
import { LayoutItem, GuestsManager,  LayoutManager } from '../store/DataStructures';
interface IProps {
    inMeeting: boolean;
    setMeeting: (inMeeting: boolean) => void;
    startCasting: (callback?: Function) => void;
    sapphirePortalComm: ISapphirePortalComm; // Assuming this type is defined elsewhere in your code
    pairingCode: string;
    meetingRoomID: string;
    endCasting: () => void;
    isCasting: boolean;
    muteSound: () => void;
    videoConferencePage: boolean;
    setVideoConference: (bool: boolean) => void;
    uCast: boolean;
    setUCast: (bool: boolean) => void;
    conferenceType: number;
    setConferenceType: (type: number) => void;
    setAdmitAll: (value: boolean) => void;
    admitAll: boolean;
    layoutManager: LayoutManager;
    guestManager: GuestsManager;
}


/**
 * This is rendered once user enters the pairing code and joins the spaces
 *  */
export default function PortalPanel(props: IProps) {
    const navigate = useNavigate();



    const { t } = useTranslation();

    const [doubleClickForSpotlight, setDoubleClickForSpotlight] = useState(true);
    const [localLayout, setLocalLayout] = useState([]);
    const [isShowPresentPanel, setShowPresentPanel] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>("equal");
    const [isConferanceActive, setIsConferanceActive] = useState<boolean>(false);
    const [currentConferance, setCurrentConferance] = useState<Array<any>>([0, '', false, '']);
    const [currentSources, setCurrentSources] = useState<Array<any>>([]);
    const [resourceSharingButton, setResourceSharingButton] = useState<boolean>(false);
    const [sourcesBarHidden, setSourcesBarHidden] = useState<boolean>(false);
    const [isInTheSameNetwork, setInTheSameNetwork] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");
    const [getUserUUID, setUserUUID] = useState("");
    const [conferenceFormOpen, setConferenceFormOpen] = useState(false);
    const [conferenceInitiatedError, setConferenceInitiatedError] = useState<boolean>(false);

    const meetingOnline = useRef(null);
    const callId = useRef(-1);

    useEffect(() => {
        setShowPresentPanel(true);
    }, [props.inMeeting]);


    useEffect(() => {
        window.addEventListener("beforeunload", handleLeaveSpaceWithTab);

        return () => {
            window.removeEventListener("beforeunload", handleLeaveSpaceWithTab);
        };
    }, []);

    const joinSpace = () => {
        // if uCast and conference type is not selected
        // none selected
        props.sapphirePortalComm.joinMeeting(props.guestManager.thisPortalGuestName, false);

        if(props.conferenceType === -1 && !props.uCast){
            props.setVideoConference(false);
            return;
        }
        // if conference is selected
        if (props.conferenceType !== -1) {
            setConferenceFormOpen(true);
            return;
        }
        // this will only run when conference is not selected but uCast is selected 
        if (props.uCast) {
                props.startCasting(() => {
                    props.setVideoConference(false);
                });
                props.sapphirePortalComm.joinMeeting(props.guestManager.thisPortalGuestName, true);
        } 
    }

    const handleLeaveSpace = async (pairingCode) => {
        handleStopCasting();
        props.setMeeting(false);
        props.sapphirePortalComm.leaveMeeting( 1, "Leaving the meeting.");
        props.setUCast(false);
        props.setConferenceType(-1);
        navigate("/");
    }

    const handleStopCasting = async () => {
        if (props.isCasting) {
            props.endCasting();
            props.sapphirePortalComm.stopCasting();
        }
    }


    const handleLeaveSpaceWithTab = () => {
        try {
            if (props.isCasting) {
                props.sapphirePortalComm.stopCasting();
            }
            props.sapphirePortalComm.leaveMeeting(2, "left the space with the tab");
        } catch (error) {
            console.log(error);
        }
    }

    if (props.videoConferencePage) {
        return (
            <>
                <VideoConferencingPage
                    meetingRoomName={props.meetingRoomID}
                    uCast={props.uCast}
                    setUCast={props.setUCast}
                    handleLeaveSpace={() => {handleLeaveSpace(props.pairingCode)}}
                    joinSpace={joinSpace}
                    conferenceType={props.conferenceType}
                    setConferenceType={props.setConferenceType}
                    disableConfrenceOptions={!(props.meetingRoomID)} 
                    startCasting={props.startCasting}
                    guestManager={props.guestManager}/>
                {/* {props.roomosDevice && props.meetingRoomID && <AddConference
                    meetingRoomID={props.meetingRoomID}
                    callId={callId.current}
                    currentConferance={currentConferance}
                    setCurrentConferance={setCurrentConferance}
                    setCallId={(value) => callId.current = value}
                    setIsConferanceActive={setIsConferanceActive}
                    sapphirePortalComm={props.sapphirePortalComm}
                    conferanceButtons={props.conferanceButtons}
                    roomosDevice={props.roomosDevice}
                    webexAuthToken={props.webexAuthToken}
                    roomOSDevices={props.roomOSDevices}
                    setSelectedRoomOSDevice={props.setSelectedRoomOSDevice}
                    isConferanceActive={isConferanceActive}
                    showConferenceButton
                    conference={conferance}
                    minimize={minimize}
                    setMinimize={setMinimize}
                    setConference={setConferance}
                    statusInterval={statusInterval}
                    leaveConferance={leaveConferance}
                    error={error}
                    showModal={showModal}
                    setError={(error) => setError(error)}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    conferenceFormOpen={conferenceFormOpen}
                    setConferenceFormOpen={(bool) => setConferenceFormOpen(bool)}
                    selectedApp={props.conferenceType}
                    setSelectedApp={props.setSelectedApp}
                    setVideoConferance={props.setVideoConference}
                    setAdmitAll={props.setAdmitAll}
                    admitAll={props.admitAll}
                    setConferenceInitiatedError={setConferenceInitiatedError}
                    conferenceInitiatedError={conferenceInitiatedError}
                    callback={()=>{
                        if (props.uCast && !conferenceInitiatedError) {
                            props.startCasting(() => {
                                props.setVideoConference(false);

                            });
                        } else if (!conferenceInitiatedError) {
                            setConferenceInitiatedError(false);
                        } else{
                            props.setVideoConference(false);
                        }
                    }}
                />} */}
            </>);
    }

   

    return (
        <div className="overallPanelLayout">
            <div className="sharingPanel">
                <div className="sharingPanelLeft" style={{ width: !isConferanceActive ? 'calc(100vw - 468px)' : '100%' }}>
                    <div className="sharingPanelLeftBar">
                        {/**Leave Space button at top */}
                        <Button variant={'danger'} onClick={() => {handleLeaveSpace(props.pairingCode)}}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                            {t('PortalApp.LeaveSpace')}
                        </Button>
                        <div className="sharingButtons">
                            {/**Share Screen button */}
                            {!props.isCasting && <Button variant={'secondary'} onClick={(event) => {props.startCasting()}} disabled={props.layoutManager.inputLayoutAsArray.length === 6}>
                                <FontAwesomeIcon icon={faShare} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                                {t('PortalApp.ShareScreen')}
                            </Button>}
                            {/** Stop Sharing button */}
                            {props.isCasting && <Button variant={'secondary'} onClick={handleStopCasting}>
                                <FontAwesomeIcon icon={faXmark} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                                {t('PortalApp.StopSharing')}
                            </Button>}
                            {/* {<Button variant={'secondary'} onClick={isMuted === undefined ? null : handleMuteClick} disabled={isMuted === undefined}>
                                {isMuted ? <FontAwesomeIcon icon={faVolume} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />: <FontAwesomeIcon icon={faVolumeSlash} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />}
                             </Button>} */}
                            {/* {isInTheSameNetwork && currentSources.length === 0 && <Button variant={'secondary'} onClick={handleResourceSharing}>
                                <FontAwesomeIcon icon={faShare} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                                {t('PortalApp.ActivateResourceSharing')}
                            </Button>} */}
                        </div>
                        {/*This component displays the Select layout button and Add conference button
                        props.connectionMapping.length !== 0 --> this  condition is for the layout button. If its true, then layout button is displayed
                        props.roomosDevice && props.meetingRoomID --> when  this is true, then Add conference button will be displayed
                        So this div tag should be displayed when either one of above condition is true
                    */}
                        {/* {(props.connectionMapping.length !== 0 || (props.roomosDevice && props.meetingRoomID)) && <div className="sharingDivs"> */}
                            {/** This displays the layout button */}
                            {/* <SelectLayout
                                layout={props.layout}
                                selectedValue={selectedValue}
                                setSelectedValue={(e) => setSelectedValue(e)}
                                setLocalLayout={setLocalLayout}
                                layoutType={props.layoutType}
                                setLayoutType={props.setLayoutType}
                                setLayoutTypeAndSendAll={props.setLayoutTypeAndSendAll}
                                sendLayoutToTheSource={props.sendLayoutToTheSource}
                                setDoubleClickForSpotlight={setDoubleClickForSpotlight}
                                connectionMapping={props.connectionMapping}
                                setLayoutAndSendToAllUponChange={props.setLayoutAndSendToAllUponChange}
                                meetingRoomID={props.meetingRoomID} /> */}

                            {/** This displays the Add conference button */}
                            {/* <AddConference
                                meetingRoomID={props.meetingRoomID}
                                callId={callId.current}
                                currentConferance={currentConferance}
                                setCurrentConferance={setCurrentConferance}
                                setCallId={(value) => callId.current = value}
                                setIsConferanceActive={setIsConferanceActive}
                                sapphirePortalComm={props.sapphirePortalComm}
                                conferanceButtons={props.conferanceButtons}
                                roomosDevice={props.roomosDevice}
                                webexAuthToken={props.webexAuthToken}
                                roomOSDevices={props.roomOSDevices}
                                setSelectedRoomOSDevice={props.setSelectedRoomOSDevice}
                                isConferanceActive={isConferanceActive}
                                showConferenceButton
                                conference={conferance}
                                minimize={minimize}
                                setMinimize={setMinimize}
                                setConference={setConferance}
                                statusInterval={statusInterval}
                                leaveConferance={leaveConferance}
                                error={error}
                                showModal={showModal}
                                setError={(error) => setError(error)}
                                handleClose={handleClose}
                                handleShow={handleShow}
                                conferenceFormOpen={conferenceFormOpen}
                                setConferenceFormOpen={(bool) => setConferenceFormOpen(bool)}
                                insidePortalPanel
                                selectedApp={props.conferenceType}
                                setSelectedApp={props.setSelectedApp}
                                setAdmitAll={props.setAdmitAll}
                                setConferenceInitiatedError={setConferenceInitiatedError}
                                conferenceInitiatedError={conferenceInitiatedError}
                                admitAll={props.admitAll}
                            /> */}
                    </div>
                    <ScreenSharePanel
                        setSelectedValue={(e) => setSelectedValue(e)}
                        meetingRoomID={props.meetingRoomID}
                        isShow={isShowPresentPanel}
                        sapphirePortalComm={props.sapphirePortalComm}
                        doubleClickForSpotlight={doubleClickForSpotlight}
                        selectedValue={selectedValue}
                        isConferanceActive={isConferanceActive}
                        displayBar={(currentSources.length > 0 && resourceSharingButton)}
                        sourcesBarHidden={sourcesBarHidden}
                        currentSources={currentSources}
                        isConferenceActive={isConferanceActive}
                        guestManager={props.guestManager}
                        layoutManager={props.layoutManager}
                    />

                </div>
                {/** Conference Action buttons */}
                {/* {isConferanceActive && <div className="sharingPanelRight">
                    <ConferenceActions
                        meetingRoomID={props.meetingRoomID}
                        webexAuthToken={props.webexAuthToken}
                        roomosDevice={props.roomosDevice}
                        callId={callId.current}
                        currentConferance={currentConferance}
                        setCurrentConferance={setCurrentConferance}
                        conferanceButtons={props.conferanceButtons}
                        sapphirePortalComm={props.sapphirePortalComm}
                        leaveConferanceEvent={leaveConferanceEvent} 
                        admitAll={props.admitAll}/>
                </div>} */}
            </div>
            {/* {currentSources.length > 0 && <ResourcesBar
                sourcesBarHidden={sourcesBarHidden}
                setSourcesBarHidden={setSourcesBarHidden}
                currentSources={currentSources}
                guestName={getUserUUID}
                pairingCode={props.pairingCode}
                sendHLSLink={props.sendHLSLink}
                searchValue={searchValue}
                setSearchValue={(value) => setSearchValue(value)} />}

            {resourceSharingButton && <SessionKeeper
                globalStateLink={props.globalStateLink}
                setCurrentSources={setCurrentSources}
                url={getStreamActivateUrl(props.globalStateLink)} />} */}

        </div>

    )

}








