import { useState, useCallback, useEffect } from 'react';
import {Screen, LayoutItem ,LayoutWithScreen} from "./DataStructures";

type LayoutScreenState = { [name: string]: LayoutWithScreen };

export const getAbbreviation = (name: string) => {
    if (!name) return ''; // Return an empty string if the name is not provided

    const words = name.split(' '); // Split the name into an array of words
    const initials = words.map(word => word.charAt(0)); // Get the first letter of each word
    const abbreviation = initials.join('').toUpperCase(); // Combine, capitalize, and return the result

    return abbreviation;
};

export const useLayoutItems = () => {
    

    const [layoutScreenState, setLayoutScreenState] = useState<LayoutScreenState>({});
    const [layoutAsArray, setLayoutAsArray] = useState<LayoutItem[]>([]); // State for the layout as an array
    const [screenAsArray, setScreenAsArray] = useState<Screen[]>([]); // State for the layout as an array

    useEffect(() => {
        const newLayoutArray = Object.values(layoutScreenState).map(ls => ls.layoutItem);
        const newScreenArray = Object.values(layoutScreenState).map(ls => ls.screen);
        const idsMatch = () => {
            // First, check if both arrays have the same length
            if (newLayoutArray.length !== newScreenArray.length) {
              return false;
            }
            return newLayoutArray.every((layoutItem, index) => {
              const screenItem = newScreenArray[index];
              return layoutItem && screenItem && layoutItem.i === screenItem.id;
            });
          };
          
        const doesIDsMatch = idsMatch();

        if (doesIDsMatch){
            setLayoutAsArray(newLayoutArray);
            setScreenAsArray(newScreenArray);
        }
    }, [layoutScreenState]);

    const upsertLayoutItemWithScreen = useCallback((name: string, layoutItem: LayoutItem, screen: Screen) => {
        setLayoutScreenState(currentState => ({
            ...currentState,
            [name]: { layoutItem, screen },
        }));
    }, []);

    const getLayoutWithScreen = useCallback((name: string): LayoutWithScreen | undefined => {
        return layoutScreenState[name];
    }, [layoutScreenState]);

    const getAllLayoutItems = useCallback((): LayoutItem[] => {
        return Object.values(layoutScreenState).map(ls => ls.layoutItem);
    }, [layoutScreenState]);

    const getAllScreens = useCallback((): Screen[] => {
        return Object.values(layoutScreenState).map(ls => ls.screen);
    }, [layoutScreenState]);

    const inputLayoutAsArray = useCallback((newLayout: LayoutItem[]) => {
        setLayoutScreenState(currentState => {
            const newLayoutItems: LayoutScreenState = {};
    
            
    
            for (const layoutItem of newLayout) {
                const existingScreen = currentState[layoutItem.i]?.screen;

                // Define a default screen object
                const defaultScreen: Screen = {
                    id: layoutItem.i,
                    screen: null
                };
    
                // Use the existing screen if available, otherwise use the default screen object
                newLayoutItems[layoutItem.i] = { layoutItem, screen: existingScreen || defaultScreen };
            }
    
            return newLayoutItems;
        });
    }, []);
    
    const deleteLayoutItemWithScreen = useCallback((id: string) => {
        let newLayout;
        setLayoutScreenState(currentState => {
            const newState = { ...currentState };
            delete newState[id];
            newLayout = newState;
            return newState;
        });
        return newLayout;
    }, []);

    return { layoutScreenState, layoutAsArray, screenAsArray, inputLayoutAsArray, getAllScreens, upsertLayoutItemWithScreen, getLayoutWithScreen, getAllLayoutItems, deleteLayoutItemWithScreen};
};
