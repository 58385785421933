import { useCallback, useEffect, useState } from 'react';
import RGL from "react-grid-layout";
import { GetRandomColor } from './GetRandomColor';
import { useTranslation } from 'react-i18next';
import { ISapphirePortalComm } from '../comm/WebSocket';
import { LayoutItem } from "../comm/DataStructures";

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './css/PanelView.scss';
import { GuestsManager, LayoutManager } from '../store/DataStructures';

export interface ConnectionMapping {
    connectionID: string;
    guestName: string;
    mainConnectionID: string;
}

// export interface LayoutItem {
//     i: string;
//     x: number;
//     y: number;
//     w: number;
//     h: number;
//     static?: boolean;
//     isResizable: boolean;
//     isDraggable: boolean;
// }

interface IProps {
    isShow: boolean;
    meetingRoomID: string;
    doubleClickForSpotlight: boolean;
    setSelectedValue: (selectedValue: string) => void;
    sapphirePortalComm: ISapphirePortalComm;
    selectedValue: string;
    isConferanceActive: boolean;
    displayBar: boolean;
    sourcesBarHidden: boolean;
    currentSources: Array<any>;
    isConferenceActive: boolean;
    guestManager: GuestsManager;
    layoutManager: LayoutManager;
}

// to get the first letters of the name
export const getAbbreviation = (name: string) => {
    if (!name) return ''; // Return an empty string if the name is not provided

    const words = name.split(' '); // Split the name into an array of words
    const initials = words.map(word => word.charAt(0)); // Get the first letter of each word
    const abbreviation = initials.join('').toUpperCase(); // Combine, capitalize, and return the result

    return abbreviation;
};

/**
 * 
 * This is where screens shared by all the users are displayed
 * 
 * @param props 
 * @returns 
 */
export default function ScreenSharePanel(props: IProps) {

    const { t } = useTranslation();

    const [screens, setScreen] = useState<Array<any>>([]);
    const [layout, setLayout] = useState<Array<any>>([]);
    

    /**
     * getWindowWidth function, when called, calculates and returns the maximum width of the window by considering
     * the inner width of the window and the offset widths of the <body> and <html> elements.
     * The useCallback hook is used to memoize the function for performance optimization.
     * 
     * window.innerWidth: The inner width of the browser window.
     * document.body.offsetWidth: The offset width of the <body> element.
     * document.documentElement.offsetWidth: The offset width of the root <html> element.
     */
    const getWindowWidth = useCallback(() => {
        return Math.max(window.innerWidth, document.body.offsetWidth, document.documentElement.offsetWidth);
    }, []);

    /**
     * getWindowHeight function, when called, calculates and returns the maximum height of the window by considering
     * the inner height of the window and the offset heights of the <body> and <html> elements.
     * The useCallback hook is used to memoize the function for performance optimization.
     * 
     * window.innerHeight: The inner height of the browser window.
     * document.body.offsetHeight: The offset height of the <body> element.
     * document.documentElement.offsetHeight: The offset height of the root <html> element.
     */
    const getWindowHeight = useCallback(() => {
        return Math.max(window.innerHeight, document.body.offsetHeight, document.documentElement.offsetHeight);
    }, []);

    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    const getRandomColor = GetRandomColor();

    // it handles changing the spotlight when user double clicks on some participants
    const handleDoubleClickSpotlightChange = (i) => {
        const layout = props.layoutManager.layoutAsArray;

        var secondaryIndex = 0;
        var tempLayout = [];

        layout.forEach((frame) => {
            if (frame.i === i) {
                tempLayout.push({ i: frame.i, guestName: frame.guestName, x: 0, y: 0, w: layout.length > 1 ? 40 : 50, h: 5, static: true, isDraggable: false, isResizable: false } as LayoutItem)
            }
            else {
                tempLayout.push(
                    { i: frame.i, x: 40, guestName: frame.guestName, y: secondaryIndex++, w: 10, h: 1, static: true, isDraggable: false, isResizable: false } as LayoutItem
                );
            }
        })
        
        props.sapphirePortalComm.layoutUpdate(tempLayout);

        const senitizedLayout = tempLayout.map(({ guestName, ...rest }) => rest);

        setLayout(senitizedLayout);
    };

    useEffect(() => {

        const layout = props.layoutManager.layoutAsArray;

        const tempScreen = layout.map((layoutItem) => (
            <div key={layoutItem.i} style={{ backgroundColor: getRandomColor(layoutItem.i) }} className="screenDiv" onDoubleClick={() => handleDoubleClickSpotlightChange(layoutItem.i)}>
                <div className="profile">
                    <div className="circle">
                        {layoutItem.i[0]}
                    </div>
                    <div id={"guestNameDiv" + layoutItem.i} className="guestNameDiv" style={{ display: layoutItem.i ? "" : "none" }}>
                        {layoutItem.guestName}
                    </div>
                </div>
                <div className={'abbreName abbreNameSmall'}>
                    {getAbbreviation(layoutItem.i)}
                </div>
            </div>
        ));

        setScreen(tempScreen);

        const senitizedLayout = layout.map(({ guestName, ...rest }) => rest);
        setLayout(senitizedLayout);

    }, [props.layoutManager.layoutAsArray]);

    // if spotlight, then divide the whole layout into 5 rows, else 1 or 2 depending on the localLayout length
    const selectedValue = "spotlight";
    const dividefactor = selectedValue === "spotlight" ? 5 : props.layoutManager.layoutAsArray.length > 2 ? 2 : 1;
    const calculated = props.currentSources.length !== 0 ? (window.innerHeight - 268) / dividefactor : (window.innerHeight - 212) / dividefactor;
    const calculatedWidth = props.isConferanceActive ? windowWidth - 468 : windowWidth;

    return (
        <div className={!props.layoutManager.layoutAsArray ? 'hidden' : props.layoutManager.layoutAsArray.length === 0 ? 'presentPanel presentPanel3' : 'presentPanel'} style={{ height: `${props.layoutManager.layoutAsArray.length !== 0 ? 'calc(100vh - 168px)' : 'calc(100vh - 112px)'}` }}>
            {props.layoutManager.layoutAsArray.length === 0 &&
                <div className='all-content'>
                    <div className="shared-content">
                        <div className='shared-content-label'>{t('PortalApp.ShareHead')}</div>
                        <p>{t('PortalApp.ShareDes')}</p>
                    </div>
                    <div className='sharedImagesDiv'>
                        <img src="./assets/multiScreen.png" />
                    </div>

                </div>
            }
            {props.layoutManager.layoutAsArray.length !== 0 && <RGL
                width={calculatedWidth}
                className='layout'
                margin={[0, 0]}
                layout={layout}
                cols={50} // number of columns in the layout
                rowHeight={calculated}
                // containerPadding={[50, 50]}
            >
                {screens}
            </RGL>}
        </div>
    );

}