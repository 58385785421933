import { useState, useCallback, useEffect } from 'react';
import { Guests, Guest } from "./DataStructures";

export const useGuestsManager = () => {
    const [guests, setGuests] = useState<Guests>({ mainPresenter: "guest", presentGuests: [] });
    const [thisPortalGuestName, setThisPortalGuestName] = useState<string>("");

    useEffect(()=>{console.warn("guests updated: ", guests)}, [guests]);

    const addGuest = useCallback((newGuest: Guest) => {
        const isExisting = guests.presentGuests.some(guest => guest.rtcConnectionID === newGuest.rtcConnectionID);
        if (isExisting) {
            console.warn("Guest with this rtcConnectionID already exists.");
            return;
        }

        setGuests(currentGuests => ({
            ...currentGuests,
            presentGuests: [...currentGuests.presentGuests, newGuest],
        }));
    }, [guests]);

    const deleteGuest = useCallback((rtcConnectionID: string) => {
        setGuests(currentGuests => ({
            ...currentGuests,
            presentGuests: currentGuests.presentGuests.filter(guest => guest.rtcConnectionID !== rtcConnectionID),
        }));
    }, []);

    const setRtcConnectionID = useCallback((name: string, rtcConnectionID:string) => {
        setGuests(currentGuests => {
            const updatedPresentGuests = currentGuests.presentGuests.map(guest => 
                guest.name === name ? { ...guest, rtcConnectionID: rtcConnectionID } : guest
            );
    
            return {
                ...currentGuests,
                presentGuests: updatedPresentGuests,
            };
        });
    }, []);

    const setGuestCasting = useCallback((rtcConnectionID: string, isCasting: boolean) => {
        setGuests(currentGuests => {
            const updatedPresentGuests = currentGuests.presentGuests.map(guest => 
                guest.rtcConnectionID === rtcConnectionID ? { ...guest, isCasting: isCasting } : guest
            );
    
            return {
                ...currentGuests,
                presentGuests: updatedPresentGuests,
            };
        });
    }, []);

    const setMainPresenter = useCallback((guestName: string | null) => {
        setGuests(currentGuests => ({...currentGuests,  mainPresenter: guestName}));
    }, []);

    const getGuests = useCallback(()=>{
        return guests;
    },[])

    return { guests, thisPortalGuestName, setThisPortalGuestName, getGuests,setGuests, addGuest, deleteGuest, setRtcConnectionID, setGuestCasting, setMainPresenter };
};
