import UserfulTooltip from "../common/UserfulTooltip";
import './SelectConferenceType.scss';
import { conferenceOptions } from "./models";
import { useTranslation } from "react-i18next";

interface IProps {
    conferenceType: number;
    setConferenceType: Function;
    disableConfrenceOptions: boolean;
}

const SelectConferenceType = (props: IProps) => {

    const { t } = useTranslation();

    // function for toggling the app
    const onToggleApp = (id: number) => {
        if (props.disableConfrenceOptions)
            return;
        if (props.conferenceType === id)
            props.setConferenceType(-1);
        else
            props.setConferenceType(id);
    }

    // add logic here for checking which app is active
    const appIsActive = (id: number) => {
        return id === props.conferenceType;
    }

    return (
        <div className="gemstone-wrapper">
            {conferenceOptions.map((item) => (
                <UserfulTooltip key={`sam-app-select-${item.id}`} title={props.disableConfrenceOptions ? t('PortalApp.DisableConference') : item.label}>
                    <div
                        key={`sam-app-select-${item.id}`}
                        className={`appSelectItem${appIsActive(item.value) ? " activeSelectItem" : ""}${props.disableConfrenceOptions ? " disabledDiv" : " enabledDiv"}`}
                        onClick={() => onToggleApp(item.value)}
                    >
                        <div className={`iamgeDiv${appIsActive(item.value) ? " activeImageDiv" : ""}`}>
                            <img src={item.src} />
                        </div>
                        <div className="imageLabel">{item.label}</div>
                    </div>
                </UserfulTooltip>
            ))}
        </div>
    );
}

export default SelectConferenceType;